import React from "react"

import { graphql } from "gatsby"

import Seo from "../components/seo"
import Hero from "../components/hero"
import Layout from "../components/layout"
// import HomeBlog from "../components/homeBlog"
import HomeTeasers from "../components/homeTeasers"
import HomeContact from "../components/homeContact"
import HomeFeatures from "../components/homeFeatures"
import HomeProducts from "../components/homeProducts"
import HomeTechnologies from "../components/homeTechnologies"

const IndexPage = ({ data }) => {
	const heroImage = data && data.datoCmsPageHome && data.datoCmsPageHome.homeHeroImage

	return (
		<Layout>
			<Seo title="Strona Główna Fit&Ride" />
			<Hero full image={heroImage} />
			<HomeProducts />
			<HomeTechnologies />
			<HomeFeatures />
			<HomeTeasers />
			<HomeContact />
			{/* <HomeBlog /> */}
		</Layout>
	)
}

export const query = graphql`
  query QueryHomePageHero {
    datoCmsPageHome {
      homeHeroImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default IndexPage
