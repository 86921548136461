import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const useStyles = makeStyles(theme => ({
	teasers: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
		}
	},
	grid: {
		position: "relative",
		"& .gatsby-image-wrapper::after": {
			position: 'absolute',
			top: 0,
			left: 0,
			content: "''",
			display: 'block',
			width: '100%',
			height: '100%',
			transition: '0.3s',
		},
		"&:hover": {
			cursor: "pointer",
			[theme.breakpoints.up('md')]: {
				"& a": {
					opacity: 0.9
				},
				"& .gatsby-image-wrapper::after": {
					opacity: 0.7,
					backgroundColor: theme.palette.text.primary,
				},
			},
		},
	},
	label: {
		position: "absolute",
		bottom: `calc(100% - ${theme.spacing(3)}px)`,
		width: theme.spacing(25),
		height: theme.spacing(6.25),
		backgroundColor: theme.palette.error.main,
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(9.25),
			height: theme.spacing(2.5),
		}
	},
	link: {
		opacity: 0,
		display: "flex",
		textDecoration: 'none',
		top: theme.spacing(20),
		bottom: theme.spacing(20),
		left: theme.spacing(9),
		right: theme.spacing(9),
		color: theme.palette.common.white,
		position: "absolute",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.error.main,
		transition: '.3s',

		[theme.breakpoints.down('sm')]: {
			opacity: 1,
			top: theme.spacing(9),
			bottom: theme.spacing(9),
		}
	},
	icon: {
		color: theme.palette.common.white,
		margin: `0 -${theme.spacing(1)}px`,
	}
}))

const HomeTeasers = () => {
	const classes = useStyles()

	const data = useStaticQuery(graphql`
  query QueryHomeTeasers {
    datoCmsPageHome {
      homePromo {
        id
        promoImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`)

	const teasers = (data && data.datoCmsPageHome && data.datoCmsPageHome.homePromo) || []

	const overlays = [
		{
			label: "Dopasuj siodło",
			slug: "/saddlefitting/pasowanie-siodel"
		},
		{
			label: "Testy",
			slug: "/saddlefitting/testy"
		},
	]

	return (
		<Container maxWidth="lg" className={classes.teasers}>
			<Grid container spacing={6}>
				{[teasers.map(({ id, promoImage }, index) => (
					<Grid key={id} item sm={6} xs={12} className={classes.grid}>
						<span className={classes.label}>&nbsp;</span>
						<GatsbyImage image={getImage(promoImage)} alt="Teasers" className={classes.image} />
						<Link to={overlays[index].slug} className={classes.link}>
							<Typography variant="h5" color="primary">{overlays[index].label}</Typography>
							<Box display="flex">
								{[...Array(3)].map((_, i) => <ChevronRightIcon key={i} fontSize="large" className={classes.icon} />)}
							</Box>
						</Link>
					</Grid>
				))]}
			</Grid>
		</Container>
	)
}

export default HomeTeasers
