import React from 'react'
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Title from '../title'
import Button from '../button'

const useStyles = makeStyles(theme => ({
	technologies: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0),
		}
	},

	image: {
		position: 'relative',
		top: 0,
		padding: 0,

		[theme.breakpoints.down('xs')]: {
			width: '330px',
			position: 'absolute',
			top: '-75px',
			left: '-20px',
			zIndex: 11
		},
	},

	productDescriptionWrapper: {

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(28),
		},
	},

	productTitle: {
		fontFamily: "Ubuntu, sans-serif",
	},

	title: {
		marginTop: theme.spacing(1),
		fontFamily: theme.typography.fontFamily,
	},

	paper: {
		position: 'relative',
		display: 'flex',
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		padding: theme.spacing(8),
		paddingLeft: 0,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2),
			marginTop: theme.spacing(8),
			padding: theme.spacing(2),
		}
	},

	link: {
		textDecoration: 'none'
	},

	button: {
		marginTop: theme.spacing(2),

		"&:hover": {
			backgroundColor: theme.palette.text.primary
		},
	}
}))

const HomeProducts = () => {
	const classes = useStyles()
	const { datoCmsPageHome: { homePageProducts = [] } } = useStaticQuery(graphql`
    query QueryHomeProducts {
      datoCmsPageHome {
        homePageProducts {
          saddleType
          caption
          description
          id
          title
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `)


	return (
		<Container maxWidth="md" className={classes.technologies}>
			{homePageProducts.map(({ id, title, caption, description, image, saddleType }) => {
				const slug = saddleType === 'Siodła skokowe' ? "siodla-skokowe" : "siodla-ujezdzeniowe"
				return (
					<Paper className={classes.paper} key={id}>
						<Grid container spacing={3}>
							<Grid item sm={6} className={classes.image}>
								<GatsbyImage image={getImage(image)} alt="Product" />
							</Grid>
							<Grid className={classes.productDescriptionWrapper} item sm={6}>
								<Title variant="h5" className={classes.productTitle}>{caption}</Title>
								<Typography
									className={classes.title}
									gutterBottom
								>{title}</Typography>
								<Typography variant="body2" gutterBottom>{description}</Typography>
								<Link to={`/siodla/${slug}`} className={classes.link}>
									<Button className={classes.button}>{saddleType}</Button>
								</Link>
							</Grid>
						</Grid>
					</Paper>
				)
			})}
		</Container>
	)
}

export default HomeProducts
