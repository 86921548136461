import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Title from "../title"

const useStyles = makeStyles(theme => ({
	technologies: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
		},
	},

	mobileTitle: {
		fontSize: '2.5rem',
	},

	description: {
		padding: theme.spacing(12),
		color: theme.palette.common.white,
		backgroundColor: "#A71C25",

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(6),
		},

		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},

	image: {
		transform: "translate(-80px, 20px)",

		[theme.breakpoints.down('md')]: {
			transform: "translate(-40px, 20px)"
		},

		[theme.breakpoints.down('sm')]: {
			transform: "unset"
		},
	}
}))

const HomeTechnologies = () => {
	const classes = useStyles()
	const {
		datoCmsPageHome: {
			homePageTechnologiesDescription,
			homePageTechnologiesTitle,
			homePageTechnologiesImage
		}
	} = useStaticQuery(graphql`
    query QueryHomeTechnologies {
      datoCmsPageHome {
        homePageTechnologiesDescription
        homePageTechnologiesTitle
        homePageTechnologiesImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

	return (
		<Container maxWidth="lg" className={classes.technologies}>
			<Grid container spacing={3}>
				<Grid item md={5} sm={12}>
					<Hidden mdUp>
						<Title className={classes.mobileTitle}>{homePageTechnologiesTitle}</Title>
					</Hidden>
					<Box
						component="div"
						className={classes.description}
						dangerouslySetInnerHTML={{
							__html: homePageTechnologiesDescription
						}}
					/>
				</Grid>
				<Grid item md={7} sm={12}>
					<Hidden smDown>
						<Title>{homePageTechnologiesTitle}</Title>
					</Hidden>
					<GatsbyImage
						alt="Technologie"
						image={getImage(homePageTechnologiesImage)}
						className={classes.image}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}

export default HomeTechnologies
