import React from 'react'
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Button from "../button"

const slugConfig = {
	"technologie": "/onas/technologie",
	"personalizacja": "/onas/personalizacja",
	"pasowanie-siodel": "/saddlefitting/pasowanie-siodel",
}

const useStyles = makeStyles(theme => ({
	features: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
		}
	},
	paper: {
		height: "100%",
		textAlign: "center",
		padding: theme.spacing(3),
		display: "flex",
		flexFlow: "column nowrap",
	},
	title: {
		fontWeight: 'bold',
		fontSize: "1.7rem",
		lineHeight: "2rem",
		minHeight: theme.spacing(8)
	},
	description: {
		marginBottom: theme.spacing(2)
	},
	link: {
		marginTop: "auto",
		textDecoration: 'none',

	},
	icon: {
		maxWidth: theme.spacing(14),
		margin: `0 auto ${theme.spacing(3)}px`
	},
	button: {
		backgroundColor: theme.palette.text.primary,

		"&:hover": {
			backgroundColor: theme.palette.error.main
		},
	},
}))

const HomeFeatures = () => {
	const classes = useStyles()
	const { allDatoCmsFeature } = useStaticQuery(graphql`
    query QueryHomeFeatures {
      allDatoCmsFeature(sort: {order: ASC, fields: originalId}) {
        nodes {
          id
          slug
          title
          textBtn
          description
          image {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  `)

	const features = (allDatoCmsFeature && allDatoCmsFeature.nodes) || []

	if (!features) return null

	return (
		<Container maxWidth="lg" className={classes.features}>
			<Grid container spacing={3}>
				{[features.map(({ id, title, description, textBtn, image, slug }) => (
					<Grid key={id} item md={3} sm={6} xs={12}>
						<Paper elevation={0} className={classes.paper}>
							<GatsbyImage image={getImage(image)} alt={title} className={classes.icon} />
							<Box
								mb={2}
								display="flex"
								alignItems="center"
								justifyContent="center"
								className={classes.title}
							>{title}</Box>
							<Box className={classes.description}>{description}</Box>
							<Link to={slugConfig[slug]} className={classes.link}>
								<Button className={classes.button}>{textBtn}</Button>
							</Link>
						</Paper>
					</Grid>
				))]}
			</Grid>
		</Container>
	)
}

export default HomeFeatures
