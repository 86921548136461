import React, { useState } from 'react'
import * as Yup from "yup"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import MuiTextField from "@material-ui/core/TextField"
import CircularProgress from '@material-ui/core/CircularProgress';


import { Field, Form, Formik } from "formik"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import encode from '../../helpers/encode';
import Title from '../title'
import Button from '../button'
import ColumnIcon from '../contactColumn/ColumnIcon'

const INITIAL_VALUES = {
	name: "",
	email: "",
	message: "",
}

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Pole jest za krótkie")
		.max(50, "Pole jest za długie")
		.required("Wymagane"),
	email: Yup.string().email("Email jest nieprawidłowy").required("Wymagane"),
	message: Yup.string()
		.min(2, "Pole jest za krótkie")
		.max(300, "Pole jest za długie")
		.required("Wymagane"),
})

const TextField = withStyles(theme => ({
	root: {
		color: theme.palette.common.black,
		"&:hover": {
			backgroundColor: theme.palette.common.white,
		},
		"& .MuiFormLabel-root": {
			color: theme.palette.text.secondary,
		},
		"& .MuiFilledInput-root": {
			borderRadius: 0,
			backgroundColor: theme.palette.common.white,
		}
	}
}))(MuiTextField)

const useStyles = makeStyles(theme => ({
	contact: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		backgroundColor: theme.palette.error.main,
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
		}
	},
	details: {
		color: theme.palette.text.secondary,
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(6)
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4)
		}
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	rules: {
		color: theme.palette.common.white,
	},
	buttonContainer: {
		display: "flex",
		marginTop: theme.spacing(6),
		justifyContent: "flex-end",
		[theme.breakpoints.down('sm')]: {
			justifyContent: "center",
		}
	},

	mobileTitle: {
		fontSize: '2.5rem',
	},

	description: {
		"& p": {
			margin: 0
		}
	},
	form: {
		backgroundColor: theme.palette.error.main
	},
	textCenter: {
		textAlign: "center"
	},
	formField: {
		color: theme.palette.grey[500],
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.spacing(4)
	},
	white: {
		color: theme.palette.common.white,
	},
	formHelper: {
		color: `${theme.palette.common.white} !important`,
		padding: `0 ${theme.spacing(0.5)}px`,
		margin: 0,
		fontSize: '14px',
		backgroundColor: theme.palette.error.main
	}
}))

const HomeContact = () => {
	const classes = useStyles()

	const [open, setOpen] = useState(false)

	const handleClose = () => setOpen(false)

	const { datoCmsPageContact } = useStaticQuery(graphql`
		query QueryHomeContact {
			datoCmsPageContact {
				contactPageDescription
				contactPageFormDescription
				contactPageFormTitle
				contactPageTitle
				contactPageColumns {
					contactPageColumnCaption
					contactPageColumnDescription
					contactPageColumnIcon
					id
				}
			}
		}
`)

	const {
		contactPageDescription,
		contactPageFormDescription,
		contactPageFormTitle,
		contactPageTitle,
		contactPageColumns
	} = datoCmsPageContact || {}

	const handleSubmit = ({ name, email, message }, actions) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": "Pasowanie siodeł formularz kontaktowy - Kontakt",
				name,
				email,
				message
			}),
		})
			.then(response => {
				if (response.ok) {
					setOpen(true)
					actions.resetForm()
				}
			})
			.catch(error => {
				console.log("Error", { error })
			})
			.finally(() => actions.setSubmitting(false))
	}


	return (
		<section className={classes.contact}>
			<Container maxWidth="lg" className={classes.container}>
				<Box mb={7} display="flex" justifyContent="center">
					<Title className={classes.mobileTitle} variant="h4" borderColor="#000" color="#fff">{contactPageTitle}</Title>
				</Box>
				<Grid container spacing={4}>
					<Grid item md={4} sm={6} xs={12}>
						<Box pr={10} pl={8} className={classes.details} height="100%">
							{contactPageColumns.map(column => (
								<Box mb={2} display="flex" key={column.id}>
									<ColumnIcon
										icon={column.contactPageColumnIcon}
										size="small"
										color="#A71C25"
										className={classes.icon}
									/>
									<Typography
										component="div"
										variant="body2"
										className={classes.description}
										dangerouslySetInnerHTML={{
											__html: column.contactPageColumnDescription
										}}
									/>
								</Box>
							))}
						</Box>
					</Grid>
					<Grid item md={8} sm={6} xs={12}>
						<Formik
							onSubmit={handleSubmit}
							initialValues={INITIAL_VALUES}
							validationSchema={ContactSchema}
						>
							{props => {
								return (
									<Form
										name="Pasowanie siodeł formularz kontaktowy - Kontakt"
										data-netlify={true}
										netlify-honeypot="bot-field"
									>
										<div style={{ display: "none" }}>
											<label>
												Don’t fill this out: <input name="bot-field" />
											</label>
										</div>

										<Typography
											variant='h3'
											className={classes.white}
											gutterBottom
										>{contactPageFormTitle}</Typography>

										<Field name="name" autoComplete="off">
											{({ field, form }) => (
												<TextField
													{...field}
													fullWidth
													variant="filled"
													id="name"
													label="Imię"
													color="secondary"
													error={Boolean(form.errors[field.name])}
													helperText={form.errors[field.name]}
													className={classes.formField}
													FormHelperTextProps={{
														classes: { root: classes.formHelper }
													}}
												/>
											)}
										</Field>

										<Field name="email" autoComplete="off">
											{({ field, form }) => (
												<TextField
													{...field}
													fullWidth
													variant="filled"
													label="Email"
													color="secondary"
													error={Boolean(form.errors[field.name])}
													helperText={form.errors[field.name]}
													className={classes.formField}
													FormHelperTextProps={{
														classes: { root: classes.formHelper }
													}}
												/>
											)}
										</Field>

										<Field name="message" autoComplete="off">
											{({ field, form }) => (
												<TextField
													{...field}
													fullWidth
													multiline
													minRows={7}
													variant="filled"
													label="Treść wiadomości"
													color="secondary"
													error={Boolean(form.errors[field.name])}
													helperText={form.errors[field.name]}
													className={classes.formField}
													FormHelperTextProps={{
														classes: { root: classes.formHelper }
													}}
												/>
											)}
										</Field>

										<Typography variant='body2' className={classes.white}>
											{contactPageFormDescription}
										</Typography>

										<Box display="flex" justifyContent="flex-end" mt={6}>
											<Button
												type="submit"
												bgColor="#000"
												size="medium"
												disabled={
													!(props.isValid && props.dirty) || props.isSubmitting
												}
												className={classes.submitButton}
												startIcon={
													props.isSubmitting && (
														<CircularProgress color="inherit" size={20} />
													)
												}>Wyślij wiadomość</Button>
										</Box>

										{/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
									</Form>
								)
							}}
						</Formik>

						<Snackbar
							open={open}
							autoHideDuration={4000}
							onClose={handleClose}
						>
							<Alert
								severity="success"
								className={classes.alert}
								action={null}
							>
								Wiadomość została wysłana, dziękujemy.
							</Alert>
						</Snackbar>

					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default HomeContact
